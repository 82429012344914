import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Navigate, useNavigate } from 'react-router-dom';
import { createClient } from '@supabase/supabase-js'
import Moment from 'react-moment';
import moment from 'moment';
import * as FileSaver from 'file-saver';
import XLSX from 'sheetjs-style';
import { data } from 'autoprefixer';

// Create a single supabase client for interacting with your database
const supabase = createClient('https://qikgpggxnrgfhmednztm.supabase.co', 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InFpa2dwZ2d4bnJnZmhtZWRuenRtIiwicm9sZSI6ImFub24iLCJpYXQiOjE2NzQwMTE2MTcsImV4cCI6MTk4OTU4NzYxN30.vmpZaG1lHwpKSSGzo2i_TL-qvAZdYuswhaKplJykrL4');
const url="https://zeus-1-olamidipupo-favour.vercel.app";
function ExportXls(data,filename){
    const filetype="application/vnd/openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const extension=".xls"
    alert("omo")
    const exportXls=async ()=>{
const ws=XLSX.utils.json_to_sheet(data);
const wb={Sheets:{data:ws},SheetNames:["data"]};
const excelBuffer=XLSX.write(wb,{bookType:"xls",type:"array"});
const dta=new Blob([excelBuffer],{type:filetype})
FileSaver.saveAs(dta,filename+extension)
    }
    exportXls().then(()=>alert("Done!"))
}
function Item(name,salary,id){
    useEffect(()=>{
        let newdata={note:"",km:"0",name:name.data.email,totalIncome:name.data.salary,salary:name.data.salary,other:"0",amountToBeGiven:name.data.salary,created_at:moment(name.data.created_at).format()}
        try{
        let olddata=JSON.parse(localStorage.getItem("gone"))
        if(olddata.includes(newdata)){
            console.log("appending not neccessary")
        }
        else{
        olddata.push(newdata)
        localStorage.setItem("gone",JSON.stringify(olddata))
        console.log("Done!")
        }
        }
        catch{
            localStorage.setItem("gone",JSON.stringify([newdata]))
            console.log("Done!")
        }
        console.log(newdata)
    },[])
    return(
        <>
        <tr>
            <td className='bg-gray-100'>{name.data.email}</td>
            <td className="p-2 bg-gray-100">{name.data.salary}</td>
            <td className='bg-gray-100'>{name.data.salary}</td>
            <td className="p-2 bg-gray-100">{0}</td>
            <td className='bg-gray-100'>{0}</td>
            <td className="p-2 bg-gray-100">{"  "}</td>
            <td className='bg-gray-100'>{name.data.salary}</td>
            <td className="p-2 bg-gray-100"><Moment format="YYYY/MM/DD hh:mm">{name.data.created_at}</Moment></td>
            </tr>
        </>
    )
}
function Item2(name,salary,id){
    const [name1,setName]=useState("");
    const [note,SetNote]=useState("");
    const [km,setKm]=useState("");
    const [email,setEmail]=useState("");
    useEffect(()=>{
async function get_owners(){
    const {data,error}=await supabase.from("owners").select().eq("owner_id",name.data.owner_id)
    console.log(error);
    console.log(data)
    return data;
}
get_owners().then((data)=>{
    console.log(data)
    try{
    SetNote(data[0].note)
    setKm(data[0].km)
    setName(data[0].name)
    setEmail(data[0].email)
    }
    catch{
        console.log("error")	
    }
    console.log(name1,note,km)
    let newdata={note:data[0].note,km:data[0].km,name:data[0].email,totalIncome:name.data.totalIncome,salary:name.data.salary,other:name.data.other,amountToBeGiven:name.data.amountToBeGiven,created_at:moment(name.data.created_at).format()}
    console.log(newdata)
    try{
        let olddata=JSON.parse(localStorage.getItem("gone"))
        if(olddata.includes(newdata)){
            console.log("appending not neccesary");
        }
        else{
        olddata.push(newdata)
        localStorage.setItem("gone",JSON.stringify(olddata))
        console.log("Done!")
        }
        }
        catch{
            localStorage.setItem("gone",JSON.stringify([newdata]))
            console.log("Done!")
        }
})
    },[])
    return(
        <>
        <tr>
            <td className='bg-gray-100'>{email}</td>
            <td className="p-2 bg-gray-100">{name.data.totalIncome}</td>
            <td className='bg-gray-100'>{name.data.salary}</td>
            <td className="p-2 bg-gray-100">{km}</td>
            <td className='bg-gray-100'>{name.data.other}</td>
            <td className="p-2 bg-gray-100">{note}</td>
            <td className='bg-gray-100'>{name.data.amountToBeGiven}</td>
            <td className="p-2 bg-gray-100"><Moment format="YYYY/MM/DD hh:mm">{name.data.created_at}</Moment></td>
            </tr>
        </>
    )
}
export default function AdminExport(){
    const [data1,setData1]=useState([]);
    const [data2,setData2]=useState([]);
   const navigate=useNavigate();
    useEffect(()=>{
//call up the function.
//check if the admin is logged in.
localStorage.setItem("gone",JSON.stringify([]))
async function get_data1(){
    const {data,error}=await supabase.from("scs").select();
    console.log(error);
    return data;
}
async function get_data2(){
    const {data,error}=await supabase.from("salary").select();
    console.log(error);
    return data;
}
get_data1().then((res)=>setData1(res))
get_data2().then((res)=>setData2(res))
    },[])
    return (
        <>
        <h1 className="text-center font-heavy text-2xl text-black-700 p-2 mt-8 mr-8 ml-8">Admin Export</h1>
    <div className='m-6'> <table>
        <tr>
        <th className='bg-blue-500 text-white border-r-8 border-white'>Courier</th>
        <th className='bg-blue-500 text-white border-r-8 border-white'>TotalIncome</th>
        <th className='bg-blue-500 text-white border-r-8 border-white'>Salary</th>
        <th className='bg-blue-500 text-white border-r-8 border-white'>Km</th>
        <th className='bg-blue-500 text-white border-r-8 border-white'>Other Amount</th>
        <th className='bg-blue-500 text-white border-r-8 border-white'>Other Reason</th>
        <th className='bg-blue-500 text-white border-r-8 border-white'>Amount to be given</th>
        <th className='bg-blue-500 text-white border-r-8 border-white'>Date/time of courier-save</th>
        </tr>
        {console.log(data1,data2)}
        {data2.map((data,id)=>{
            return <Item2 data={data}/>
        })}
        {data1.map((data,id)=>{
            return <Item data={data}/>
        })}
        </table>
        <button type="button" className='absolute bottom-0 right-0 bg-green-500 hover:bg-blue-500 rounded-md p-2 border-1' onClick={()=>{
            //export data from localstorage.
            let data=JSON.parse(localStorage.getItem('gone'))
             data = data.filter((value, index) => {
                const _value = JSON.stringify(value);
                return index === data.findIndex(obj => {
                  return JSON.stringify(obj) === _value;
                });
              });
           const filetype="application/vnd/openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
            const extension=".xls"
            const exportXls=async ()=>{
                const ws=XLSX.utils.json_to_sheet(data);
        const wb={Sheets:{data:ws},SheetNames:["data"]};
        const excelBuffer=XLSX.write(wb,{bookType:"xls",type:"array"});
        const dta=new Blob([excelBuffer],{type:filetype})
        FileSaver.saveAs(dta,"res"+extension)
            }
            exportXls().then(()=>{localStorage.setItem("gone",JSON.stringify([]));navigate(0)})
            //return (<ExportXls data={data} filename={"res"} />)
        }}>Export</button>
        <button type="button" className='absolute bottom-0 left-0 bg-red-500 hover:bg-pink-700 rounded-md p-2 border-1' onClick={()=>{
            //delete all db data.
            async function delete_all_scs(){
                const {error}=await supabase.from("scs").delete().eq("sg","xhoom");
                console.log(error);
            }
            async function delete_all_owners(){
                const {error}= await supabase.from("owners").delete().eq("sg","xhoom");
                console.log(error);
            }
            async function delete_all_salary(){
                const {error}= await supabase.from("salary").delete().eq("sg","xhoom");
                console.log(error);
            }
            delete_all_scs().then(()=>{
                delete_all_owners().then(()=>{
                    delete_all_salary().then(()=>{
                        setData1([]);
                        setData2([]);
                        localStorage.setItem("gone",JSON.stringify([]))
                    })
                })
            })
        }}>Reset</button>
        </div>
        </>
    )
}