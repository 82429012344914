import React from 'react';
function Err(){
    return (
        <div className="">
            <center>
    <img src="images/sad.png" className='h-10 w-10 mt-9'/>
    <h1 className="text-center p-5">{404}-Page Not Found</h1>
    </center>
    </div>);
}
export default Err;