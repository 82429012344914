import React, { useState } from 'react';
import { Outlet, Link } from "react-router-dom";
import { createClient } from '@supabase/supabase-js'
import { useNavigate } from 'react-router-dom';
function Admin(){
    //const [show, setShow] = useState(false);
    //const [modalTitle,setModalTitle]=useState();
    //const [ModalInfo, setModalInfo]=useState();
    const [baskets,setBaskets]=useState(0);
    const [pay,setPay]=useState(0)
    const navigate=useNavigate()
    //const [km,setKM]=useState(0)
    useState(()=>{
      console.log(localStorage.getItem('admin')==null)
      if(localStorage.getItem('admin')==null){
        navigate('/')
      }
    },[])
    const [gasoline,setGasoline]=useState(0)
    return (
        <div>
        <h1 className="text-center font-heavy text-3xl text-black-700">Admin Page</h1>
        <div className='mt-4'>
          <center>
          <ul>
            <li className=''>Standard Basket Price</li>
            <input onChange={(e)=>setBaskets(e.target.value)}  className="m-1  py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md sm:text-sm focus:ring-1" type="text" placeholder="6400" />
            <li className=''>Default Courier Salary/ address</li>
            <input onChange={(e)=>setPay(e.target.value)} className="m-1 py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md sm:text-sm focus:ring-1" type="text" placeholder="500" />
            <li className=''>Gasoline Cost</li>
            <input onChange={(e)=>setGasoline(e.target.value)} className="m-1  py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md sm:text-sm focus:ring-1" type="text" placeholder="70" />
          </ul>
          </center>
           </div>
           <p><button type="button" class="btn btn-primary bg-orange-500 rounded-md px-2 my-4 mx-2 h-7 absolute right-0" onClick={()=>{
           async function omo(){
            const supabase = createClient('https://qikgpggxnrgfhmednztm.supabase.co', 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InFpa2dwZ2d4bnJnZmhtZWRuenRtIiwicm9sZSI6ImFub24iLCJpYXQiOjE2NzQwMTE2MTcsImV4cCI6MTk4OTU4NzYxN30.vmpZaG1lHwpKSSGzo2i_TL-qvAZdYuswhaKplJykrL4');
            //const url="https://zeus-1-olamidipupo-favour.vercel.app";
            const { data, error } = await supabase.from("cdata").upsert({id:1,standard_basket_price:baskets, default_courier_salary:pay, gasoline_cost:gasoline})
            console.log(data)
            console.log(error)
           }
           omo();
           }}>Save</button></p>
           <div className="flex justify-center">
           <button type="button" class="btn btn-primary bg-green-500 rounded-md h-8 absolute bottom-2 px-2"><Link to="/scs">Special Courier Salary</Link></button></div>
           <button type="button" class="btn btn-primary bg-blue-500 rounded-md h-8 absolute bottom-2 right-0 px-2"><Link to="/admin_export">Courier Details</Link></button>
           <button type="button" class="btn btn-primary bg-red-500 rounded-md h-8 absolute top-2 right-0 px-2" onClick={()=>{localStorage.removeItem('admin');navigate('/uac');}}>Logout</button>
           <Outlet/>
        </div>

    );
}
export default Admin;