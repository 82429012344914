import React, { useState,useEffect } from 'react';
import {BsFillPlusCircleFill} from "react-icons/bs";
import { Outlet, Link } from "react-router-dom";
import { createClient } from '@supabase/supabase-js'
import { useNavigate } from 'react-router-dom';

// Create a single supabase client for interacting with your database
const supabase = createClient('https://qikgpggxnrgfhmednztm.supabase.co', 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InFpa2dwZ2d4bnJnZmhtZWRuenRtIiwicm9sZSI6ImFub24iLCJpYXQiOjE2NzQwMTE2MTcsImV4cCI6MTk4OTU4NzYxN30.vmpZaG1lHwpKSSGzo2i_TL-qvAZdYuswhaKplJykrL4');
//const data=[{id:"id-000", name:"Agboola Olamidipupo", salary:1000},{id:"id-001", name:"Agboola Dolabomi", salary:500}]
function Item(name,salary,id){
    const navigate=useNavigate();
    return(
        <>
        <tr>
            <td className='bg-gray-100'>{name.name.email}</td>
            <td className="p-2 bg-gray-100">{name.name.salary}</td>
            <button className="m-2 btn btn-primary bg-red-600" onClick={()=>{
                async function delete_data(){
                    const {error}=await supabase.from("scs").delete().eq("id",name.name.id);
                    return error;
                }
                delete_data().then((error)=>{
                    //deleted data
                    console.log(error)
                    navigate('/scs');
                })
            }}>delete</button>
            </tr>
        </>
    )
}
function Scs(){
    const [ddata,setrData]=useState([]);
    const [email,setEmail]=useState();
    const [salary, setSalary]=useState();
    const [newdata,setNewData]=useState([]);
    //let newdata=[];
    console.log(ddata)
   useEffect(()=>{
        async function startup(){
            const {data,error}=await supabase.from('scs').select()
            //setData(data)
            console.log(data)
            return data;
           

        }
        startup().then(data=>{
            setrData(data) && console.log(ddata)
        });
    },[]);
   
    return (
<>
    <h1 className="text-center font-heavy text-2xl text-black-700 p-2 mt-8 mr-8 ml-8">Special Courier Salary</h1>
    <div className='m-6'> <table>
        <tr>
        <th className='bg-blue-500 text-white border-r-8 border-white'>E-mail</th>
        <th className='bg-blue-500 text-white'>Salary</th>
        </tr>
    {ddata.map((data,id)=>{
        return <Item name={data}/>
    })}
    </table> </div>
    <div className="m-5">
        <h1 className="text-xl text-center">Special Courier Salary/ <BsFillPlusCircleFill onClick={()=>{
            //add the user to a list.
            if(email.length<2 && salary.length<=0){
                alert("Input valid details.")
            }
            else{
                //const date=new Date();
                newdata.push({email:email,salary:String(salary)});
                ddata.push({email:email,salary:String(salary)});
                console.log(newdata);
                setEmail("")
                setSalary()
            }
        }}/> address</h1>
        <input className="m-1  py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md sm:text-sm focus:ring-1" type="text" placeholder="E-Mail" onChange={(e)=>{setEmail(e.target.value)}}/>
        <input className="m-1  py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md sm:text-sm focus:ring-1" type="text" placeholder="450" onChange={(e)=>{setSalary(parseInt(e.target.value))}} />
        <button className="m-2 btn btn-primary bg-orange-600 rounded-md p-2 absolute right-0" onClick={()=>{
             async function push_to_supabase(){
                console.log(newdata);
                const {data,error}=await supabase.from("scs").insert(newdata);
                console.log(error)
                return error;
            }
            push_to_supabase().then(error=>{
                if(error!=null){
                    alert(error)
                }
                else{
                    alert("Saved data")
                    setNewData([]);
                }
                })
        }}>Save</button>
        </div>
</>
    );
}
export default Scs;