import React, {useState,useEffect} from 'react';
import { Swiper, SwiperSlide } from "swiper/react";
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';
//import SwiperCore, { Autoplay, Navigation, Pagination } from "swiper";
import SwiperCore, { EffectCoverflow, Pagination } from "swiper";
import axios from 'axios';
import { Navigate, useNavigate } from 'react-router-dom';
//looking for a way to order it as requested.
import { createClient } from '@supabase/supabase-js'

// Create a single supabase client  for interacting with your database
const supabase = createClient('https://qikgpggxnrgfhmednztm.supabase.co', 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InFpa2dwZ2d4bnJnZmhtZWRuenRtIiwicm9sZSI6ImFub24iLCJpYXQiOjE2NzQwMTE2MTcsImV4cCI6MTk4OTU4NzYxN30.vmpZaG1lHwpKSSGzo2i_TL-qvAZdYuswhaKplJykrL4');
let index=0;
SwiperCore.use([EffectCoverflow, Pagination]);
const url="https://zeus-1-olamidipupo-favour.vercel.app";
let bigdata={};
//let data=[{id:"ïd-000",name:"Agboola Olamidipupo",info:"If they don't pay on time, kill em",extra_payment:"1000",payment:"Yes",phone:"+2347049603393",from:"12:00",to:"5:00",city:"Nigeria",address:"Block 1, room 214, Fajuyi hall OAU Ife."},{id:"ïd-001",name:"Agboola Olamidipupo Favour",info:"STFU",payment:"Yes",phone:"+2347049603395",from:"11:00",to:"4:00",city:"Nigeria",address:"Block 2, room 304, Fajuyi hall OAU Ife."}];
function Card1(name,info,payment,phone,from,to,regio,address){
  //console.log(name.name);
  let phone1="tel:"+name.name.phone;
  //console.log(phone1);
  let zeus=name.name;
  console.log(zeus)
  return (
<div className='border-2 border-fuchsia-700 rounded-xl'>
  <ul className="p-3">
    <li className="bg-gray-300 mb-2">{zeus.twitterhandle=="None"?" ":zeus.twitterhandle}</li>
    <li className="bg-gray-300 mb-2">{name.name.zip=="None"?" ":zeus.zip}</li>
    <li className="bg-gray-300 mb-2">{name.name.payment=="None"?" ":zeus.payment}</li>
    <li className="bg-gray-300 mb-2">{name.name.extra_payment=="None"?" ":zeus.extra_payment}</li>
    <li className="bg-gray-300 mb-2"><a href={phone1}>{name.name.phone=="None"?" ":zeus.phone}</a></li>
    <li className="bg-gray-300 mb-2">{name.name.from=="None"?" ":zeus.from} {name.name.to=="None"?" ":zeus.to}</li>
    <li className="bg-gray-300 mb-2">{name.name.city=="None"?" ":zeus.city}</li>
    <li className="bg-gray-300 mb-2">{name.name.address=="None"?" ":zeus.address}</li>
  </ul>
</div>
  );
}
function Card2(name,id){
  const navigate=useNavigate();
  const [sd,setSd]=useState("");
  const [sp,setSp]=useState("");
  const [name1,setName]=useState(name.name.id);
  const [id2,setId]=useState(name.name.id);
  //console.log(name.name.id);
  const [note,setNote]=useState("");
  const [extrapayment,setExtrapayment]=useState("0");
  const [basketBack,setBasketBack]=useState(0);
  const dta=name.name;
  const [cl,setCl]=useState([true,true,true,true,true,true,true,true,true]);
  const clicked=["blue","pink","blue","green","orange","blue","blue","blue","blue"]
  function handlebg(index){
    setCl(!cl[index]);
    return cl;
    if(cl==true){
      return clicked[index];
    }
  }
  //console.log(name.name)
  async function save_task(){
    console.log(dta)
    if(String(parseInt(extrapayment))=="NaN" && extrapayment.length>0){
      alert("Please enter a valid number");
      return;
    }
  const data3={};
  /*let industry_field=(String(dta.payment)=="undefined" || String(dta.payment)==="None"?"":String(dta.payment));
 //industry_field=industry_field.replace(" - Nem vette át","")
 //industry_field=industry_field.replace(" -Cash","")
 //industry_field=industry_field.replace(" -Terminal","")
// industry_field=industry_field.replace("No pay","")
  let annualrevenue_field=(String(dta.extra_payment)=="undefined" || String(dta.extra_payment)=="None"?"":String(dta.extra_payment));
  //annualrevenue_field=annualrevenue_field.replace("-Cash:","")
  //annualrevenue_field=annualrevenue_field.substring(0,annualrevenue_field.indexOf("-Cash:"))
  //annualrevenue_field+=" -Cash:  "+String(parseInt(extrapayment));
  let gender_field=(String(dta.gender)=="undefined"||String(dta.gender)=="None"?"":String(dta.gender))
 // gender_field=annualrevenue_field.substring(0,annualrevenue_field.indexOf("-Basket Back:"))+" - Basket Back: "+String(basketBack);
  let note_field=String(note);
  // remove the original added data.
  */
  let industry_field=(String(dta.payment)==="undefined" || String(dta.payment)==="None"?"":String(dta.payment));
  //industry_field=industry_field.replace(" - Nem vette át","")
  //industry_field=industry_field.replace(" -Cash","")
  //industry_field=industry_field.replace(" -Terminal","")
  //industry_field=industry_field.replace("No pay","")
   let annualrevenue_field=(String(dta.extra_payment)=="undefined" || String(dta.extra_payment))=="None"?" -Cash:  "+String(parseInt(extrapayment)) : String(dta.extra_payment)+" -Cash:  "+String(parseInt(extrapayment));
   //annualrevenue_field=annualrevenue_field.replace("-Cash:","")
   //annualrevenue_field=annualrevenue_field.substring(0,annualrevenue_field.indexOf("-Cash:"))
   //annualrevenue_field+=" -Cash:  "+String(parseInt(extrapayment));
   //alert(dta.gender)
   let gender_field=(String(dta.gender)=="undefined"||String(dta.gender)=="None"?"":String(dta.gender))+" - Basket Back: "+String(basketBack);
   //gender_field=annualrevenue_field.substring(0,annualrevenue_field.indexOf("-Basket Back:"))+" - Basket Back: "+String(basketBack);
   let note_field=String(note);
   // remove the original added data.
  if (sd=="no"){
    //Object.assign(data3,v)
    //{"industry":String(name.name.payment_status)+" Nem vette át"}
    industry_field+=" Nem vette át";
  }
    if(sp=="yes"){
      industry_field+=" -Cash ";
    }
    else if(sp=="terminal"){
      industry_field+=" -Terminal";
    }
    else if(sp=="no"){
      industry_field+=" -No pay";
    }
    Object.assign(data3,{"industry":industry_field,"annualrevenue":annualrevenue_field,"gender":gender_field,"state":note_field})
    const data=new Map([[dta.id,data3]]);
    const data4=Object.fromEntries(data);
    console.log(data4)
axios.post(url+"/save_data",data4,{headers:{'Authorization':`Bearer ${localStorage.getItem("token")}`}}).then((res)=>{
      console.log(res.data);
      if(res.data.status!=200){
        alert("Error saving task");
        return ;
      }
      //pop from list.
      alert("Task saved")
      let data5=JSON.parse(localStorage.getItem("tasks"));
      for (let i=0;i<data5.length;i++){
if(data5[i].id==dta.id){
  let del=data5[i];
  del.payment=industry_field;
  del.extra_payment=annualrevenue_field;
  del.gender=gender_field
  var deletedshii=JSON.parse(localStorage.getItem("deleted"))
  console.log(deletedshii)
  try{
    console.log("omo!")
    for(let i2=0; i2<deletedshii.length;i2++){
      if(deletedshii[i2].id==del.id){
        deletedshii.splice(i2,1);
        break;
      }
    }
    deletedshii.push(del)
    localStorage.setItem("deleted",JSON.stringify(deletedshii));
  }
  catch{
    localStorage.setItem("deleted",JSON.stringify([del]));
  }
  data5.splice(i,1);
      }
    }
    localStorage.setItem("tasks",JSON.stringify(data5));
    navigate(0);
    }).catch((err)=>{alert("Error saving task")});
    /*const {error} = await supabase.from('customers').insert([data3]);
    console.log(error);
  console.log(data3);
  alert("Task saved");
  //pop from list.
let data5=JSON.parse(localStorage.getItem("tasks"));
index=data4.indexOf(dta);
alert(index);*/
//Object.assign(bigdata,data2)
//console.log(bigdata);
//localStorage.setItem("bigdata",JSON.stringify(bigdata));
//prolly use supabase to store the data as time goes on.
  }
  return (
<div className='border-2 border-fuchsia-700 rounded-xl'>
  <ul className="p-2">
    <li id={name.name.id+"name"} className='bg-blue-200 text-center text-2xl'>{name.name.twitterhandle}</li>
    <li className='text-center text-xl'> Sikeres volt?</li>
    <center><ul class="grid  grid-cols-2">
    <li>
        <input type="radio" id={"sd-yes"+name1} name="succesful delivery" value="yes" class="hidden peer" required/>
        <label for={"sd-yes"+name1} onClick={()=>setSd("yes")}  className="inline-flex py-2 mx-0 peer-checked:bg-blue-700 btn btn-primary bg-green-800 rounded-xl px-10  border-3 hover:bg-blue-700 border-green-300">                           
            <div className="block">
                <div className="text-lg font-semibold">IGEN</div>
            </div>
        </label>
        </li>
        <li>
        <input type="radio" id={"sd-no"+name1} name="succesful delivery" value="no" className="hidden peer" required/>
        <label for={"sd-no"+name1} onClick={()=>setSd("no")}  className="inline-flex py-2 mx-0 peer-checked:bg-blue-700 btn btn-primary bg-red-800 rounded-xl px-10  border-3 hover:bg-blue-700 border-green-300">                           
            <div class="block">
                <div className="text-lg font-semibold">NEM</div>
            </div>
        </label>
        </li>
        </ul>
        </center>
    <li className='text-center text-xl'>Standard payment?</li>
    <center><ul class="grid  grid-cols-3">
    <li>
        <input type="radio" id={"sp-yes"+name1} name="standard payment" value="yes" class="hidden peer" required/>
        <label for={"sp-yes"+name1} onClick={()=>setSp("yes")}  className="inline-flex py-2 mx-0 peer-checked:bg-blue-700 btn btn-primary bg-green-800 rounded-xl px-10  border-3 border-green-300">                           
            <div className="block">
                <div className="text-sm">KP-t kaptam</div>
            </div>
        </label>
        </li>
        <li>
        <input type="radio" id={"sp-terminal"+name1} name="standard payment" value="terminal" className="hidden peer" required/>
        <label for={"sp-terminal"+name1} onClick={()=>setSp("terminal")}  className="inline-flex py-2 mx-0 peer-checked:bg-blue-700 btn btn-primary bg-blue-300 rounded-xl px-10  border-3 hover:bg-blue-700 border-green-300">                           
            <div class="block">
                <div className="text-sm">Terminál</div>
            </div>
        </label>
        </li>
        <li>
        <input type="radio" id={"sp-no"+name1} name="standard payment" value="no" className="hidden peer" required/>
        <label for={"sp-no"+name1} onClick={()=>setSp("no")}  className="inline-flex py-2 mx-0 peer-checked:bg-blue-700 btn btn-primary bg-red-800 rounded-xl px-10  border-3 hover:bg-blue-700 border-green-300">                           
            <div class="block">
                <div className="text-sm">Nem adott pénzt</div>
            </div>
        </label>
        </li>
        </ul>
        </center>
    <li className="text-center text-xl">Extra fizetés</li>
   <input placeholder='Összeg' onChange={(e)=>{parseInt(e.target.value)==NaN?alert("Légyszi válaszd ki, hogy kié a kocsi."):setExtrapayment(e.target.value)}} className="mt-1 px-3 py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full  h-5000 rounded-md sm:text-sm focus:ring-1"/>
    <li className="text-center text-xl">Elhozott kosarak száma</li>
    <center><ul class="grid  grid-cols-4">
    <li className='inline-flex justify-center'>
        <input type="radio" id={"b-0"+name1} name="basket back" value="yes" class="hidden peer" required/>
        <label for={"b-0"+name1} onClick={()=>{setBasketBack(0)}}  className="inline-flex py-2 mx-0 peer-checked:bg-blue-700 btn btn-primary bg-green-800 rounded-xl px-10  border-3 border-green-300">                           
            <div className="block">
                <div className="text-lg font-semibold">0</div>
            </div>
        </label>
        </li>
        <li>
        <input type="radio" id={"b-1"+name1} name="basket back" value="terminal" className="hidden peer" required/>
        <label for={"b-1"+name1} onClick={()=>setBasketBack(1)}  className="inline-flex py-2 mx-0 peer-checked:bg-blue-700 btn btn-primary bg-green-800 rounded-xl px-10  border-3 hover:bg-blue-700 border-green-300">                           
            <div class="block">
                <div className="text-lg font-semibold">1</div>
            </div>
        </label>
        </li>
        <li>
        <input type="radio" id={"b-2"+name1} name="basket back" value="no" className="hidden peer" required/>
        <label for={"b-2"+name1} onClick={()=>setBasketBack(2)}  className="inline-flex py-2 mx-0 peer-checked:bg-blue-700 btn btn-primary bg-green-800 rounded-xl px-10  border-3 hover:bg-blue-700 border-green-300">                           
            <div class="block">
                <div className="text-lg font-semibold">2</div>
            </div>
        </label>
        </li>
        <li>
        <input type="radio" id={"b-3"+name1} name="basket back" value="no" className="hidden peer" required/>
        <label for={"b-3"+name1} onClick={()=>setBasketBack(3)}  className="inline-flex py-2 mx-0 peer-checked:bg-blue-700 btn btn-primary bg-green-800 rounded-xl px-10  border-3 hover:bg-blue-700 border-green-300">                           
            <div class="block">
                <div className="text-lg font-semibold">3</div>
            </div>
        </label>
        </li>
        </ul>
        </center>
    <input placeholder="Megjegyzés" onChange={(e)=>setNote(e.target.value)} id={name.name.id+"note"} className="mt-1 px-3 py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full  h-5000 rounded-md sm:text-sm focus:ring-1"/>
    <button type="button" onClick={()=>{save_task()}} className='btn btn-primary bg-green-700 hover:bg-blue-700 rounded-xl p-2'>Mentés</button>
  </ul>
</div>
  );
}
function Slide(name,info,payment,extra_payment,phone,from,to,regio,address,id,gender){
  const navigate=useNavigate();
  //let data=[{id:"ïd-000",name:"Agboola Olamidipupo",info:"If they don't pay on time, kill em",payment:"Yes",phone:"+2347049603393",from:"12:00",to:"5:00",city:"Nigeria",address:"Block 1, room 214, Fajuyi hall OAU Ife."}];
  return (<Swiper
      slidesPerView={1}
      direction="horizontal"
      centeredSlides={true}
      onSlideChange={() => console.log("slide change")}
      onSwiper={swiper => console.log(swiper)}
className="m-2"
    >
      <SwiperSlide><Card1 name={name} info={info} extra_payment={extra_payment} payment={payment} phone={phone} from={from} to={to} regio={regio} address={address} gender={gender}/></SwiperSlide><SwiperSlide><Card2 name={name} id={id}/></SwiperSlide></Swiper>);
}
function TestSlider(){
  const [rdata, setRdata] = useState([]);
  const navigate=useNavigate();
  useEffect(() => {
    const token=localStorage.getItem('token');
    const tasks=JSON.parse(localStorage.getItem('tasks'));
    let owner_id;
    console.log(token);
    axios.get(url+'/ping',{headers:{'Authorization':`Bearer ${token}`}}).then(res => {
      console.log(res.data);
      if(res.status==200){
      if(res.data.status=="200"){
        //console.log(tasks);
        setRdata(tasks)
      }
      else{
        localStorage.removeItem('token');
        //localStorage.removeItem('tasks');
        navigate('/uac');
      }}
  }).catch((err)=>{
    console.log(err);
    localStorage.removeItem('token');
    //localStorage.removeItem('tasks');
    navigate('/uac');
  })
  axios.get(url+"/get_id",{headers:{'Authorization': `Bearer ${localStorage.getItem('token')}`}}).then((res)=>{
    owner_id=res.data.id});
}, []);
return (
  <div>
    <h1 className="text-center font-heavy text-2xl text-fuchsia-700 ">Cím kártyák</h1>
    <button onClick={()=>{navigate('/')}} className="btn btn-primary bg-blue-400 hover:bg-green-500 rounded-xl p-2">Lista nézet</button>
    <button onClick={()=>{
      //recover deleted tasks
      navigate('/deleted_tasks');
    }} className="btn btn-primary bg-green-500 ml-8 hover:bg-blue-400 rounded-xl p-2">a törölt feladatok visszaállítása</button>
    <button onClick={()=>{navigate('/settlements')}} className="btn btn-primary bg-green-500 absolute right-0 hover:bg-blue-400 rounded-xl p-2">Elszámolás</button>

   { rdata.map(key => {
   return (<SwiperSlide><Slide id={key.id} name={key.name} info={key.info} extra_payment={key.extra_payment} payment={key.payment_status} phone={key.phone} from={key.from} to={key.to} city={key.city} address={key.address} zip={key.zip} twitterhandle={key.twitterhandle} gender={key.gender}/></SwiperSlide>);
   })}
</div>
);
}
export default TestSlider;
