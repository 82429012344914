import React, { useState,useEffect } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import Moment from 'react-moment';
import ReactLoading from 'react-loading';
import styled from "styled-components";
import Modal from 'react-overlays/Modal';
import axios from 'axios';
//import swal from 'sweetalert';
import Swal from 'sweetalert2'
import { Navigate, useNavigate } from 'react-router-dom';
const url="https://zeus-1-olamidipupo-favour.vercel.app";
let customers = [];
const Backdrop = styled("div")`
  position: fixed;
  z-index: 1040;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0.5;
`;
const RandomlyPositionedModal = styled(Modal)`
  position: fixed;
  width: 350px;
  z-index: 1040;
  top: ${() => 50}%;
  left: ${() => .1}%;
  border: 1px solid #FF00FF;
  border-radius: 10px;
  background-color: white;
  padding: 20px;
`;
function App() {
  const [characters, updateCharacters] = useState(customers);
  const navigate=useNavigate()
  //const navigate=useNavigate();
  useEffect(()=>{
    const token=localStorage.getItem('token');
    if (token==null){
      navigate("/uac")
    }
    else{
      //fetch tasks.
      //alert("fetching tasks");
      try{
        let dara=JSON.parse(localStorage.getItem('tasks'));
        if(dara.length>0){
          //if there is data in the local storage, use it.
          Swal.fire({
            title: 'Do you want to use the local data?',
            showDenyButton: true,
            confirmButtonText: 'Yes',
            denyButtonText: `No`,
          }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
              navigate('/tasks')
              return '';
            } else if (result.isDenied) {
              console.log("cancelled")
              }})
            }
          }
      catch(err){
      }
      axios.get(url+"/tasks",{headers:{'Authorization': `Bearer ${token}`}}).then((res)=>{
        console.log(res.data);
        customers=res.data.data;
        //sort the customers.
        updateCharacters(customers)
      }).catch((err)=>{
        console.log(err);
        alert(err);
    })
  }
  },[])

  const renderBackdrop = (props) => <Backdrop {...props} />;
  function save(){
    //Save the ordered data.
    //<ReactLoading type='balls'  height={667} width={375} />
    console.log(characters)
    localStorage.setItem('tasks',JSON.stringify(characters));
    //this is the ordered form of the list. Send this to the server
  }
  function handleOnDragEnd(result) {
    if (!result.destination) return;

    const items = Array.from(characters);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    updateCharacters(items);
  }
  return (
    <div className="App">
      <header className="App-header">
        <h1 className="text-center font-heavy text-2xl text-fuchsia-700 ">Lista nézet</h1>
        <button className="bg-pink-700 text-white font-bold py-2 px-4 rounded" onClick={()=>{localStorage.removeItem("token");localStorage.removeItem("deleted");navigate("/uac")}}>Logout</button>
        <DragDropContext onDragEnd={handleOnDragEnd}>
          <Droppable droppableId="characters">
            {(provided) => (
              <ul className="characters" id="shittyparent" {...provided.droppableProps} ref={provided.innerRef}>
                {characters.map(({id, name, from,to,city,address,twitterhandle}, index) => {
                  return (
                    <Draggable key={id} draggableId={id} index={index}>
                      {(provided) => (
                        <li className="flex rounded-3xl border-4 m-2 hover:border-fuchsia-700"   ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                          <p className="flex-initial w-32 align-top my-5 p-2 text-sm font-serif italic font-light">
                            {city=="None"?" ":city}
                          </p>
                          <p className="flex-initial w-64 align-top my-5 p-2 text-sm font-serif italic font-light">
                            { twitterhandle=="None"?" ":twitterhandle }
                          </p>
                          <p className="flex-initial w-64 align-top my-5 p-2 text-sm font-serif italic font-light">{address=="None"?" ":address}
                          </p>
                          <p className='flex-initial w-64 my-6'>{from=="None"?" ":from}<p>{to=="None"?" ":to}</p></p>
                        </li>
                      )}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
              </ul>
            )}
          </Droppable>
        </DragDropContext>
      </header>
      <div className='sticky bottom-1'>
      <ul className='sticky bottom-1 grid  grid-cols-2'>
        <li className='sticky bottom-1 left-1'>
        <input type="radio" id={"me"} name="succesful delivery" value="yes" class="hidden peer" required/>
        <label for={"me"} onClick={()=>save()}  className="inline-flex py-2 mx-0 peer-checked:bg-blue-700 btn btn-primary bg-pink-700 rounded-xl px-10  border-3 hover:bg-blue-700 border-green-300">                           
            <div className="block">
                <div className="text-lg font-semibold">mentés</div>
            </div>
        </label>
        </li>
        <li className='sticky bottom-1 right-1'>
        <input type="radio" id={"me"} name="succesful delivery" value="yes" class="hidden peer" required/>
        <label for={"me"} onClick={()=>navigate('/tasks')}  className="inline-flex py-2 mx-0 peer-checked:bg-blue-700 btn btn-primary bg-green-700 rounded-xl px-10  border-3 hover:bg-blue-700 border-green-300">                           
            <div className="block">
                <div className="text-lg font-semibold">Cím kártyák</div>
            </div>
        </label>
        </li>
        </ul>
        </div>
    </div>
  );
}

export default App;
