import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter,Routes,Route} from "react-router-dom";
import './index.css';
import App from './App';
import Login from './uac';
import Admin from './admin';
//import Tasks from './delivery_tasks.js';
import TestSlider from './test_slider.js';
import Settlement from './settlements.js';
import Err from './err.js';
import Set2 from './set2';
import Scs from './scs';
import AdminExport from './export_admin';
import DeletedSlider from './deleted';
import  Omo from './testbtn';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
          <Route index element={<App />} />
          <Route path="uac" element={<Login />} />
          <Route path="admin" element={<Admin />} />
          <Route path="tasks" element={<TestSlider/>}/>
          <Route path="settlements" element={<Settlement/>}/>
          <Route path="*" element={<Err/>}/>
          <Route path="set" element={<Set2/>} />
          <Route path="scs" element={<Scs/>} />
          <Route path="admin_export" element={<AdminExport/>}/>
          <Route path="deleted_tasks" element={<DeletedSlider/>}/>
          <Route path="testbtn" element={<Omo/>}/>
          </Routes>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
