import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Navigate, useNavigate } from 'react-router-dom';
import { createClient } from '@supabase/supabase-js'

// Create a single supabase client for interacting with your database
const supabase = createClient('https://qikgpggxnrgfhmednztm.supabase.co', 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InFpa2dwZ2d4bnJnZmhtZWRuenRtIiwicm9sZSI6ImFub24iLCJpYXQiOjE2NzQwMTE2MTcsImV4cCI6MTk4OTU4NzYxN30.vmpZaG1lHwpKSSGzo2i_TL-qvAZdYuswhaKplJykrL4');
const url="https://zeus-1-olamidipupo-favour.vercel.app";
function Set2(){
    const [note,setNote]=useState();
    const [totalIncome,setTotalIncome]=useState(0);
    const [salary,setSalary]=useState(0);
    const [fuelFee,setFuelFee]=useState(0);
    const [other,setOther]=useState(0);
    const [amountToBeGiven,setAmountToBeGiven]=useState(0);
    let maindata;
    let totalsp=0;
    let totalep=0;
    let totalunsuccessful=0;
    let totalc;
    let totalstandard=0;
    let totalnokosar=0;
    let data;
    let mdata;
    let id;
    useEffect(()=>{
        axios.get(url+'/tasks',{headers:{'Authorization': `Bearer ${localStorage.getItem('token')}`}}).then((res)=>{
            //console.log(res.data)
            maindata=res.data.data;
            for(let i=0;i<Object.keys(maindata).length;i++){
                //console.log(maindata[i])
                if(maindata[i].payment_status.includes("Nem vette át",(maindata[i].payment_status.length-15))==false){
                    //alert("+1")
                    totalsp+=1;
                    //alert(JSON.stringify(maindata[i]))
                    //alert(totalsp)
                }
//alert(totalsp)
if ((maindata[i].payment_status.includes("-Cash",(maindata[i].payment_status.length-6)) || maindata[i].payment_status.trim().length===0) && !maindata[i].payment_status.includes("Kosár",(maindata[i].payment_status.length-7))){
    totalstandard+=1;
    //alert(JSON.stringify(maindata[i]))
                    //alert(totalstandard)
}
                try{
                    console.log(maindata)
                    let all_nums=maindata[i].extra_payment.match(/\d+/g);
                var tb=parseInt(all_nums[all_nums.length-1])
                if(!isNaN(tb) && !(maindata[i].payment_status.includes("-Terminal",(maindata[i].payment_status.length-10)))){
                    totalep+=tb;
                };}
                catch(e){
                    console.log("Error")
                }
                if(((maindata[i].payment_status.includes("Nem vette át",(maindata[i].payment_status.length-15))  && maindata[i].payment_status.includes("Kosár",(maindata[i].payment_status.length-26)))) || maindata[i].payment_status.trim().length===0)
                totalnokosar+=1
                // if(maindata[i].payment_status.includes("Kosár",(maindata[i].payment_status.length-15)))
                //totalnokosar+=1
            }
            totalc=maindata.length
        }).catch((err)=>{
            console.log(err)
        navigate("/uac");
    })
        axios.get(url+"/get_id",{headers:{'Authorization': `Bearer ${localStorage.getItem('token')}`}}).then((res)=>{
            id=res.data.id;
            async function fetch_em(){
                const {data,error}=await supabase.from('owners').select().eq('owner_id',id);
                //console.log(cdata);
                console.log(data);
                const data2=data[0];
                console.log(data2)
                const amt=parseInt(data2.amount);
                const km=parseInt(data2.km);

                const car_owner=parseInt(data2.car_owner);
                //setFuel()
                //setTotalIncome(totalsp*cdata.standard_basket_price + totalep)
                //perform calculations.
                //total income=total standard payments* basket price +extra payment
                //salary=total number of addresses *(salary per address) - (total number of unsuccesful deluveries , where the Industry field of the given customer contained this text: Kosárelhozás), *(salary/address)
                //fuel fee=km*gasoline fee, if car is companies, value=0
            } 
            let drf;
            async function fetch_em2(){
                const {data,error}=await supabase.from('cdata').select();
                //alert(JSON.stringify(data))
                console.log(data)
                console.log(data[0].standard_basket_price);
                drf=parseInt(data[0].standard_basket_price);
                let cs=parseInt(data[0].default_courier_salary);
                console.log(drf)
                setTotalIncome(totalstandard*drf + totalep)
                setSalary(totalc*cs - totalnokosar*cs)
                //alert(totalc)
                //alert(totalnokosar)
                //alert(cs)
                console.log(data)
                setFuel(data);
            }
            async function setFuel(data3){
                //const {data3,error}=await supabase.from('cdata').select();
                drf=parseInt(data3[0].standard_basket_price);
                let cs=parseInt(data3[0].default_courier_salary);
                let fuel;
                let salary=totalc*cs - totalnokosar*cs;
                //console.log(totalstandard*drf)
                let ti=totalstandard*drf + totalep;
                async function runup(){
                    const {data,error2}=await supabase.from('owners').select().eq('owner_id',id);
                    //console.log(parseInt(data[0].gasoline_cost))
                if(data[0].car_owner=="me"){
                    fuel=parseInt(data[0].km)*parseInt(data3[0].gasoline_cost);
                    setFuelFee(fuel)
                }
                setOther(parseInt(data[0].amount))
                //alert(String(totalc-totalnokosar)*parseInt(data3[0].default_courier_salary))
                setAmountToBeGiven((totalstandard*parseInt(data3[0].standard_basket_price) + totalep)-((totalc-totalnokosar)*parseInt(data3[0].default_courier_salary))-fuel+parseInt(data[0].amount));
                //alert(totalIncome)
                return {other:data[0].amount,amt:((totalstandard*parseInt(data3[0].standard_basket_price) + totalep)-((totalc-totalnokosar)*parseInt(data3[0].default_courier_salary))-fuel+parseInt(data[0].amount)),fuel:fuel,salary:salary,ti:ti}
            }
            runup().then((data)=>{
                async function saveDb(){
                    const {error}=await supabase.from("salary").upsert({salary:data.salary,fuelFee:data.fuel,amountToBeGiven:data.amt,totalIncome:data.ti,other:data.other,owner_id:id})
                    console.log(id)
                    console.log(salary)
                    return error;}
                    saveDb();
            });
        }
            function mdata(){
                //alert(totalsp)
            }
            fetch_em();
            fetch_em2();
            mdata()
        }).catch((err)=>{
            console.log(err);
        })
        async function saveDb(){
            const {error}=await supabase.from("salary").upsert({salary:salary,fuelFee:fuelFee,amountToBeGiven:amountToBeGiven,totalIncome:totalIncome,other:other,owner_id:id})
            console.log(id)
            return error;
            //setAmountToBeGiven((totalsp*parseInt(data3[0].standard_basket_price) + totalep)+((totalc-totalunsuccessful)*parseInt(data3[0].default_courier_salary))+fuel+other);
                //alert(totalIncome)
        }
        //saveDb().then((error)=>console.log(error))
},[])
    const navigate=useNavigate();
    return (
     <>
     <ul className="mt-10 pt-10">
        <center>
        <div className="">
<li className="text-center text-xl pb-2">Napi teljes készpénz bevétel:</li>
<li className=""><p name="total_incodme" style={{display:"inline"}} className="mb-2 bg-yellow-300 rounded-md border-2">{totalIncome} HUF</p></li>
</div>
<div className="">
<li className="text-center text-xl pb-2">Fizetésed:</li>
<li className=""> <p name="total_income" style={{display:"inline"}} className="mb-2 bg-yellow-300 rounded-md border-2">{salary} HUF</p></li>
</div>
<div className="">
<li className="text-center pb-2 text-xl"> Üzemanyag költségtérítés:</li>
<li className="text-center text-xl"><p name="total_income" style={{display:"inline"}} className="mb-2 bg-yellow-300 rounded-md border-2">{fuelFee} HUF</p></li>
</div>
<div className="">
<li className="text-center pb-2 text-xl">Egyéb elszámolt összeg:</li>
<li className=""><p name="total_income" style={{display:"inline"}} className="mb-2 bg-yellow-300 rounded-md border-2">{other} HUF</p></li>
</div>
<div className=" ">
<li className="text-center pb-2 text-xl">Leadandó összeg:</li>
<li className=""><p name="total_income" style={{display:"inline"}} className="mb-2 bg-yellow-400 rounded-md border-2">{amountToBeGiven} HUF</p></li>
</div>
</center>
     </ul>
     <div className="">
    <button className="bg-orange-500 bottom-0 absolute left-0 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" onClick={()=>navigate('/')}>Vissza a lista nézetre.</button>
    <button className="bg-orange-500  bottom-0 absolute right-0  hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" onClick={()=>navigate('/tasks')}>Ok</button>
    </div>
     </>   
    );
}
export default Set2;