import React, { useState,useEffect } from 'react';
import { BsWindowSidebar } from 'react-icons/bs';
import Axios from'axios';
import {Navigate,useNavigate} from 'react-router-dom';
import { createClient } from '@supabase/supabase-js'
import axios from 'axios';
//import { useNavigate } from 'react-router-dom';

// Create a single supabase client for interacting with your database
const supabase = createClient('https://qikgpggxnrgfhmednztm.supabase.co', 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InFpa2dwZ2d4bnJnZmhtZWRuenRtIiwicm9sZSI6ImFub24iLCJpYXQiOjE2NzQwMTE2MTcsImV4cCI6MTk4OTU4NzYxN30.vmpZaG1lHwpKSSGzo2i_TL-qvAZdYuswhaKplJykrL4');
const url="https://zeus-1-olamidipupo-favour.vercel.app";
function Settlement(){
    const navigate=useNavigate();
    const [note,setNote]=useState();
    const [car_owner,setCar_owner]=useState("");
    const [km,setKm]=useState();
    const [amount,setAmount]=useState(0);
    const [name,setName]=useState("");
    const [email,setEmail]=useState("");
    useEffect(()=>{axios.get(url+"/get_id",{headers:{Authorization:` Bearer ${localStorage.getItem("token")}`}}).then((res)=>{setName(res.data.name);setEmail(res.data.email);console.log(res)}).catch((err)=>{console.log(err)})},[]);
    async function submit(){
        //Do save
        //save to a supabase db
        //load the data in the field.
        async function bzeus(res){
            id=res.data.id;
            const {data,error}=await supabase.from('owners').select()
            setNote(data[0].note)
            setNote(data[0].car_owner)
            setKm(data[0].km)
            setAmount(data[0].amount)
        }
        async function zeus(res){
            id=res.data.id
            console.log(id)
            if(car_owner==""){
                alert(" Légyszi válaszd ki, hogy kié a kocsi.")
                return;
            }
            if(String(parseInt(km))=="NaN"){
                alert("Please enter the km as numbers")
                return;
            }
            if(String(parseInt(amount))=="NaN"){
                alert("Please enter the amount as numbers")
                return;
            }
            const data={owner_id:id,car_owner:car_owner,km:String(parseInt(km)),amount:String(parseInt(amount)),note:note,name:name,email:email}
        const {error}= await supabase.from('owners').upsert(data)
        console.log(error)
        navigate("/set")
        }
        let id;
        const token=localStorage.getItem("token")
        Axios.get(url+"/get_id",{headers:{Authorization:` Bearer ${token}`}}).then((res)=>{zeus(res)}).catch((err)=>{
            console.log(err)
            alert(err.status);
        })
    }
    return (
        <>
         <h1 className="text-center font-heavy text-2xl text-fuchsia-700 p-2 mt-8 mr-8 ml-8">Elszámolás</h1>
        <div>
        <h1 className="text-center text-xl">Kinek a kocsijával mentél?</h1>
        <center>
        <div>
            <center><ul className='grid  grid-cols-2'>
        <li>
        <input type="radio" id={"me"} name="succesful delivery" value="yes" class="hidden peer" required/>
        <label for={"me"} onClick={()=>setCar_owner("me")}  className="inline-flex py-2 mx-0 peer-checked:bg-blue-700 btn btn-primary bg-green-700 rounded-xl px-10  border-3 hover:bg-blue-700 border-green-300">                           
            <div className="block">
                <div className="text-lg font-semibold">Sajáttal</div>
            </div>
        </label>
        </li>
        <li>
        <input type="radio" id={"company"} name="succesful delivery" value="yes" class="hidden peer" required/>
        <label for={"company"} onClick={()=>setCar_owner("company")}  className="inline-flex py-2 mx-0 peer-checked:bg-blue-700 btn btn-primary bg-pink-700 rounded-xl px-10  border-3 hover:bg-blue-700 border-green-300">                           
            <div className="block">
                <div className="text-lg font-semibold"> Cégessel</div>
            </div>
        </label>
        </li>
        </ul></center>
        </div></center>
</div>
<div>
    <h1 className="text-center text-xl">Megtett km</h1>
    <div><input name="KM" required pattern='[0-9]{0,5}' value={km} onChange={(e)=>setKm(e.target.value)} placeholder="Km" className="m-1  py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md sm:text-sm focus:ring-1"/></div>
</div>
<div>
    <h1 className="text-center text-xl"></h1>
    <div><input name="amount" required pattern='[0-9]{0,5}' value={amount} onChange={(e)=>setAmount(e.target.value)} placeholder="Összeg" className="mt-1 px-3 py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md sm:text-sm focus:ring-1"/><input name="notes" onChange={(e)=>setNote(e.target.value)} value={note} placeholder="Mi volt a kiadás vagy bevétel?" className="mt-1 px-3 py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md sm:text-sm focus:ring-1"/></div>
    <div><button type="submit" onClick={()=>{submit()}} className="btn btn-primary rounded-xl px-5 py-2 bg-green-700 absolute bottom-0 right-0">Mentés</button></div>
</div>
        </>
    )
}
export default Settlement;