import React, { useState,useEffect } from 'react';
import axios from 'axios';
import swal from 'sweetalert';
import { useNavigate } from 'react-router-dom';
const url="https://zeus-1-olamidipupo-favour.vercel.app";
//const url2="http://localhost:3000";
function Login(){
  const navigate=useNavigate();
  const [email,setEmail]=useState();
  const [password,setPassword]=useState();
  function login(){
    //tryna login.
    //alert("loginning in");
    if(email==="admin@heiss.ru" && password==="admin!@#"){
      localStorage.setItem("admin","true");
      navigate("/admin");
      return ""
    }
    axios.post(url+"/login",{email:email,password:password}).then((res)=>{
      console.log(res);
    console.log(res.data);
    if(res.data.status==404 || res.data.status==400){
      swal("oops","invalid credentials","error");
    }
    if(res.data.status==200){
      //add token to local storage and redirect to overview page.
      localStorage.setItem("token",res.data.token);
    navigate("/");
    }
  }).catch((err)=>{
    console.log(err);
    swal("oops","something went wrong","error");
    });
  }
return (
    <>
    <h1 className="text-center font-heavy text-2xl text-fuchsia-700 p-7 m-8">COURIER</h1>
    <div className="bg-white border rounded-xl  p-5 border-sky-700 ">
        <form>
    <label class="block">
  <span class="after:content-['*'] after:ml-0.5 after:text-red-500 block text-sm font-medium text-slate-700">
    Email
  </span>
  <input type="email" name="email" onChange={(e)=>{setEmail(e.target.value)}} className="mt-1 px-3 py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md sm:text-sm focus:ring-1" placeholder="you@example.com" />
</label>
<label class="block">
  <span class="after:content-['*'] after:ml-0.5 after:text-red-500 block text-sm font-medium text-slate-700">
    Password
  </span>
  <input type="password" onChange={(e)=>{setPassword(e.target.value)}} name="Password" className="m-1 px-3 py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md sm:text-sm focus:ring-1" placeholder="*********" />
</label>
<button type="button" onClick={()=>{login()}} className='btn-primary btn border-2 rounded-xl p-1 m-1 border-sky-200'>Login</button>
</form>
</div>
    </>
);
}
export default Login;